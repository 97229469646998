<script lang="ts" setup>
import { LineItem, PropertyGroupOptionCart } from "@shopware-pwa/types";

const props = withDefaults(
  defineProps<{
    layout: "sidecart" | "cart";
    cartItem: LineItem;
    maxQty?: number;
    favoriteItem?: string | null;
    voucherIsFavoriteItem?: boolean;
    isChangeable?: boolean;
  }>(),
  {
    maxQty: 100,
    favoriteItem: null,
    voucherIsFavoriteItem: false,
    isChangeable: true,
  },
);

const { cartItem } = toRefs(props);

const isLoading = ref(false);
const { codeErrorsNotification } = useCartNotification();
const { pushError } = useNotificationsEx();

const { refreshCart } = useCart();
const { itemOptions, removeItem, itemRegularPrice, itemSpecialPrice, itemQuantity, changeItemQuantity } =
  useCartItem(cartItem);
const { setFavoriteItem } = useMaasVoucher();

const quantity = ref();
syncRefs(itemQuantity, quantity);

const isPromotion = computed(() => cartItem.value.type === "promotion" || cartItem.value.type === "nav-voucher");

const changeFavoriteItem = async (id: string) => {
  isLoading.value = true;

  try {
    await setFavoriteItem(id);
    await refreshCart();
  } catch (error: any) {
    let messages = error?.response?.data?.messages ?? [];

    for (const message of messages) {
      pushError(message.message);
    }

    if (messages.length === 0) {
      pushError("Lieblingsteil konnte nicht gesetzt werden.");
    }
  }

  isLoading.value = false;
};

const updateQuantity = async (quantity: number | undefined) => {
  if (quantity === itemQuantity.value) return;

  isLoading.value = true;

  await changeItemQuantity(Number(quantity));
  codeErrorsNotification();

  isLoading.value = false;
};
const debounceUpdate = useDebounceFn(updateQuantity, 800);

watch(quantity, () => debounceUpdate(quantity.value));

const removeCartItem = async () => {
  isLoading.value = true;
  await removeItem();
  codeErrorsNotification();

  isLoading.value = false;
};

const getNumbers = (min: number, max: number, step: number) => {
  const result = [];
  for (let i = min; i <= max; i += step) {
    result.push(i);
  }
  return result;
};

const regularPrice = computed(() => {
  return itemRegularPrice.value ?? cartItem.value.priceDefinition?.listPrice;
});

const specialPrice = computed(() => {
  if (
    itemSpecialPrice.value ||
    (cartItem.value.priceDefinition?.price && Number(cartItem.value.priceDefinition?.price) != regularPrice.value)
  ) {
    return itemSpecialPrice.value ?? cartItem.value.priceDefinition?.price;
  }

  return false;
});
</script>

<template>
  <li
    v-if="!isPromotion"
    class="@container/checkoutcartitem pt-3 pb-4 first:border-t-px border-b-px"
    data-testid="checkoutcartlistitem"
  >
    <div class="flex gap-4 outline-maas-border-grey-light flex items-start">
      <SharedProxyImg
        :modifiers="{
          w: 72,
          sh: 0.5,
        }"
        v-if="cartItem?.cover?.url"
        :src="cartItem.cover.url"
        alt=""
        class="mt-1 aspect-ratio-[2/3] max-w-18 w-full"
        data-testid="cart-product-image"
      />

      <div class="flex-1 @md/checkoutcartitem:flex @md/checkoutcartitem:gap-4">
        <div class="flex-1">
          <h4 class="text-small @xl/checkoutcartitem:text-p font-bold" data-testid="cart-product-name">
            {{ cartItem.label }}
          </h4>

          <div class="mt-2 @xl/checkoutcartitem:flex @xl/checkoutcartitem:gap-12">
            <p v-if="itemOptions" class="mb-2 @xl/checkoutcartitem:w-1/3">
              <span
                v-for="option in itemOptions"
                :key="(option as PropertyGroupOptionCart).group"
                class="block text-small"
                data-testid="cart-product-options"
              >
                {{ option.group }}: {{ (option as PropertyGroupOptionCart).option }}
              </span>
            </p>
            <p>
              <span v-if="cartItem.payload.productNumber" class="block text-small">Artikel-Nr. {{ cartItem.payload.productNumber.split("-")[0] }}</span>

              <SharedProductAvailabilityTime
                :available-stock="(cartItem?.payload as any)?.stock"
                :class="{
                  'text-maas-brand-success': (cartItem?.payload as any)?.stock > 0,
                }"
                :next-availablity-date="(cartItem?.payload as any)?.cmw_leadTimeCalculation"
                :show-hint="false"
                :status-code="(cartItem?.payload as any)?.cmw_statusCode"
                class="text-small"
              />
            </p>
          </div>
        </div>

        <div
          class="@md/checkoutcartitem:flex-col @md/checkoutcartitem:items-end col-span-2 flex items-center justify-between"
        >
          <h5 class="text-small font-bold @xl/checkoutcartitem:text-p">
            <SharedPrice
              v-if="specialPrice"
              :class="{
                ' text-red-700': regularPrice,
              }"
              :value="specialPrice"
              class="inline-block font-semibold justify-end"
              data-testid="checkoutcartitemCurrentPrice"
            />
            <SharedPrice
              v-if="regularPrice"
              :class="{
                'text-maas-typography-text-grey-medium line-through': specialPrice,
              }"
              :data-testid="specialPrice ? 'checkoutcartitemOldPrice' : 'checkoutcartitemCurrentPrice'"
              :value="regularPrice"
              class="text-right"
            />
          </h5>

          <div v-if="isChangeable" class="flex flex-row items-center gap-2 mt-2 mb-1">
            <select
              v-model="quantity"
              :disabled="isLoading"
              class="bg-maas-background-default w-full rounded p-1 border-1 border-maas-border-grey-normal focus:outline-1 focus:outline-maas-border-primary"
              data-testid="cart-product-qty-select"
              name="quantity"
            >
              <option
                v-for="qty in getNumbers(
                  cartItem.quantityInformation?.minPurchase || 1,
                  cartItem.quantityInformation?.maxPurchase || maxQty,
                  cartItem.quantityInformation?.purchaseSteps || 1,
                )"
                :key="qty"
                :value="qty"
              >
                {{ qty }}
              </option>
            </select>
            <button
              v-if="voucherIsFavoriteItem && cartItem?.payload?.favoriteSelectable"
              :class="{ 'text-gray-500': isLoading }"
              :disabled="isLoading"
              class="block p-2.5 bg-maas-background-grey-medium rounded"
              data-testid="product-favorite-item-button"
              type="button"
              @click="changeFavoriteItem(cartItem.id)"
            >
              <svg
                class="w-3 h-3"
                fill="none"
                height="20"
                viewBox="0 0 23 20"
                width="23"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  :class="{
                    'fill-maas-icon-primary': favoriteItem === cartItem.id,
                  }"
                  class="stroke-maas-border-primary stroke-2 p-4"
                  d="M19.3658 10.5465L11.0243 18.8079L2.68276 10.5465C2.13256 10.0111 1.69917 9.36756 1.4099 8.65644C1.12062 7.94533 0.981713 7.18201 1.00193 6.41458C1.02214 5.64714 1.20104 4.8922 1.52735 4.19729C1.85367 3.50239 2.32033 2.88258 2.89795 2.37689C3.47558 1.8712 4.15165 1.49059 4.88359 1.25902C5.61554 1.02745 6.38751 0.949941 7.15088 1.03137C7.91425 1.11281 8.6525 1.35142 9.31912 1.73218C9.98575 2.11294 10.5663 2.6276 11.0243 3.24376C11.4842 2.63208 12.0654 2.12191 12.7316 1.74519C13.3978 1.36847 14.1345 1.1333 14.8958 1.05442C15.657 0.975529 16.4263 1.05461 17.1556 1.28672C17.8848 1.51883 18.5583 1.89897 19.1339 2.40334C19.7095 2.90772 20.1748 3.52547 20.5007 4.21793C20.8266 4.9104 21.006 5.66267 21.0278 6.42767C21.0495 7.19267 20.9131 7.95393 20.6272 8.6638C20.3412 9.37367 19.9118 10.0169 19.3658 10.5532"
                />
              </svg>
            </button>
            <button
              :class="{ 'text-gray-500': isLoading }"
              :disabled="isLoading"
              class="block p-2.5 bg-maas-background-grey-medium rounded"
              data-testid="product-remove-button"
              type="button"
              @click="removeCartItem"
            >
              <svg class="h-3 w-3" height="1000" viewBox="0 0 875 1000" width="875" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M0 281.296l0 -68.355q1.953 -37.107 29.295 -62.496t64.449 -25.389l93.744 0l0 -31.248q0 -39.06 27.342 -66.402t66.402 -27.342l312.48 0q39.06 0 66.402 27.342t27.342 66.402l0 31.248l93.744 0q37.107 0 64.449 25.389t29.295 62.496l0 68.355q0 25.389 -18.553 43.943t-43.943 18.553l0 531.216q0 52.731 -36.13 88.862t-88.862 36.13l-499.968 0q-52.731 0 -88.862 -36.13t-36.13 -88.862l0 -531.216q-25.389 0 -43.943 -18.553t-18.553 -43.943zm62.496 0l749.952 0l0 -62.496q0 -13.671 -8.789 -22.46t-22.46 -8.789l-687.456 0q-13.671 0 -22.46 8.789t-8.789 22.46l0 62.496zm62.496 593.712q0 25.389 18.553 43.943t43.943 18.553l499.968 0q25.389 0 43.943 -18.553t18.553 -43.943l0 -531.216l-624.96 0l0 531.216zm62.496 -31.248l0 -406.224q0 -13.671 8.789 -22.46t22.46 -8.789l62.496 0q13.671 0 22.46 8.789t8.789 22.46l0 406.224q0 13.671 -8.789 22.46t-22.46 8.789l-62.496 0q-13.671 0 -22.46 -8.789t-8.789 -22.46zm31.248 0l62.496 0l0 -406.224l-62.496 0l0 406.224zm31.248 -718.704l374.976 0l0 -31.248q0 -13.671 -8.789 -22.46t-22.46 -8.789l-312.48 0q-13.671 0 -22.46 8.789t-8.789 22.46l0 31.248zm124.992 718.704l0 -406.224q0 -13.671 8.789 -22.46t22.46 -8.789l62.496 0q13.671 0 22.46 8.789t8.789 22.46l0 406.224q0 13.671 -8.789 22.46t-22.46 8.789l-62.496 0q-13.671 0 -22.46 -8.789t-8.789 -22.46zm31.248 0l62.496 0l0 -406.224l-62.496 0l0 406.224zm156.24 0l0 -406.224q0 -13.671 8.789 -22.46t22.46 -8.789l62.496 0q13.671 0 22.46 8.789t8.789 22.46l0 406.224q0 13.671 -8.789 22.46t-22.46 8.789l-62.496 0q-13.671 0 -22.46 -8.789t-8.789 -22.46zm31.248 0l62.496 0l0 -406.224l-62.496 0l0 406.224z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </li>
</template>